.pagination li.page-item.disabled button{
    pointer-events: none;
}
.loader{
    position: fixed;
}
.loader span{
    color: white;
    background: #B78B1E;
}
table.table thead tr th a:hover, .filter-top:hover:before, .filter-bottom:hover:before{
    color: #001E61 !important;
}
.pagination li button.dot-page{
    pointer-events: none;
}
.success-sync{
    z-index: 9;
}
.Toastify__close-button>svg{
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(0%,-50%);
}


.single-filter {
    display: flex;
}
.single-filter .ant-select {
    margin-left: 20px;
    min-width: 120px;
    max-width: 120px;
    width: auto !important;
}
.single-filter .ant-select .ant-select-selector {
    border: none;
    border-bottom: 1px solid #D9D9D9;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 0 0 5px 0;
    border-radius: 0 !important;
    min-height: 34px;
}
.single-filter .ant-select .ant-select-selector .ant-select-selection-item {
    /*line-height: normal;*/
    /*font-size: 14px;*/
    font-family: 'Rubik';
    line-height: 25px;
}
.single-filter .ant-select .ant-select-selection-search {
    top: -5px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    bottom: 0;
}
.single-filter .ant-select .ant-select-arrow {
    top: 42% !important;
}
.left-member-detail ul li a {
    word-break: break-all;
}

.single-detail p {
    word-break: break-word;
}
:where(.css-dev-only-do-not-override-diro6f).ant-empty .ant-empty-image svg{
    width: 100% !important;
}
.update-div{
    text-align: right;
    margin-top: 20px;
}
.filter-section{
    margin-top: 10px;
}
.clear-icon{
    position: absolute;
    right: 5px;
    top: 2px;
    cursor: pointer;
}
.clear-icon:before {
    content: "\e901";
    color: #aaa;
    font-size: 12px;
    position: relative;
    top: -2px;
}
.clear-icon{
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    transition: all 0.5s ease;
}
.search-right .form-control{
    padding: 0 23px 9px 23px;
}

.ant-select-dropdown {
    width: 200px !important;
}

footer .container {}
.login-footer {
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 450px;
}

@media(max-width:1650px){
    .single-filter .ant-select {
        margin-left: 15px;
        min-width: 87px;
        max-width: 87px;
    }
}
@media(max-width:1365px){
    .single-filter .ant-select {
        margin-left: 10px;
        min-width: calc(100% - 121px);
        max-width: calc(100% - 121px);
        float: right;
    }
}
@media(max-width:991px){
    footer .container, .login-footer {
        position: relative;
        bottom: 0;
    }
    .single-filter:nth-child(3) {
        margin-right : 0;
    }
    .filter-option .single-filter {
        width : calc(33.3% - 12px);
    }
    .ant-select-dropdown {
        width: 120px !important;
    }
}
@media(max-width:767px)
{
    .filter-section .single-filter
    {
        margin-right : 10px;
        width        : calc(50% - 5px);
    }
    .single-filter:nth-child(even) {
        margin-right : 0;
    }
    .single-filter:nth-child(3) {
        margin-right : 10px;
    }
    .single-filter {
        display: inline-block;
        width: 100%;
    }
    .ant-select-dropdown {
        width: 160px !important;
    }
}
@media(max-width:575px){
    .single-filter .ant-select {
        min-width: calc(100% - 113px);
        max-width: calc(100% - 113px);
        float: right;
    }
    .filter-section .single-filter
    {
        margin-right : 0;
        width        : 100%;
    }
}
.single-filter .ant-select .ant-select-arrow{
    display: none;
}
.ant-select-selection-overflow-item .ant-select-selection-item-remove {
    overflow: initial;
}
