body {
  margin:0px;
  padding:0px;
  overflow-x: hidden;
  font-family: 'Rubik';
  background: #F5F5F5;
}
h1,h2,h3,h4,h5,h6 {
    margin:0px;
    font-weight:normal;
    padding:0px;
}
p {
    margin:0px;
    padding:0px;
    font-weight:normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #3B3B3B;
}
img {
    border:none;
}
ul {
    margin:0px;
    padding:0px;
}
ul li {
    list-style-type:none;
}
a, a:hover {
    text-decoration:none;
    transition: all 0.5s ease;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Regular.eot');
    src: url('../fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Regular.woff2') format('woff2'),
        url('../fonts/Rubik-Regular.woff') format('woff'),
        url('../fonts/Rubik-Regular.ttf') format('truetype'),
        url('../fonts/Rubik-Regular.svg#Rubik-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Light.eot');
    src: url('../fonts/Rubik-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Light.woff2') format('woff2'),
        url('../fonts/Rubik-Light.woff') format('woff'),
        url('../fonts/Rubik-Light.ttf') format('truetype'),
        url('../fonts/Rubik-Light.svg#Rubik-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Medium.eot');
    src: url('../fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Medium.woff2') format('woff2'),
        url('../fonts/Rubik-Medium.woff') format('woff'),
        url('../fonts/Rubik-Medium.ttf') format('truetype'),
        url('../fonts/Rubik-Medium.svg#Rubik-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-SemiBold.eot');
    src: url('../fonts/Rubik-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-SemiBold.woff2') format('woff2'),
        url('../fonts/Rubik-SemiBold.woff') format('woff'),
        url('../fonts/Rubik-SemiBold.ttf') format('truetype'),
        url('../fonts/Rubik-SemiBold.svg#Rubik-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?4ryqsw');
  src:  url('../fonts/icomoon.eot?4ryqsw#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?4ryqsw') format('truetype'),
    url('../fonts/icomoon.woff?4ryqsw') format('woff'),
    url('../fonts/icomoon.svg?4ryqsw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


::-moz-selection {
  color: #fff;
  background: #B78B1E;
}

:focus-visible,
:focus {
    outline: none !important;
}

::selection {
  color: #fff;
  background: #B78B1E;
}

.loader {
    position: absolute;
    left: 0;
    top: 130px;
    width: 100%;
    z-index: 9;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader.loader1 {
    top: 50px;
    width: calc(50% + 15px);
}
.loader span {
    color: #001E61;
    font-size: 14px;
    font-weight: 400;
    background: rgba(160, 116, 8, 0.4);
    padding: 3px 11px;
    border-radius: 3px;
}

.form-contain {
    height: 100vh;
    overflow: hidden;
    background: #FFFFFF;
}
.form-contain .row {
    height: 100%;
}
.left-form .text-center {
    width: 100%;
}
.left-form {
    width:450px;
    margin:auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    align-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
}
.error-input .error-msg {
    font-size: 14px;
    position: absolute;
    bottom: -26px;
}
.form-inner {
    margin-top: 60px;
    border: 1px solid #D9D9D9;
    border-radius: 30px;
    padding: 50px 45px;
    width: 100%;
    background: #fff;
}
.form-inner h2 {
    font-weight: 400;
    color:#001E61;
    line-height: 30px;
    font-size: 26px;
    margin-bottom: 5px;
}
.form-inner p {
    font-weight: 400;
    color:#797979;
    line-height: 16px;
    font-size: 14px;
}
.form-inner form {
    margin-top: 45px;
}
.form-inner form .form-group {
    margin-top: 15px;
    margin-bottom: 20px;
    float: left;
    width: 100%;
    position: relative;
}
.form-control {
    border:none;
    border-bottom: 1px solid #959595;
    line-height: 19px;
    font-size: 16px;
    font-weight: 400;
    color:#000;
    transition: all 0.5s ease;
    border-radius: 0;
    padding: 0 25px 15px 0;
}
.form-control:focus {
  box-shadow: none;
}
.form-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    clear: both;
    margin-bottom: 35px;
}
.form-inner form input:-ms-input-placeholder {
  color: #A4AAB8 !important;
  opacity: 1 !important;
}

.form-inner form input::-moz-placeholder {
  color: #A4AAB8 !important;
  opacity: 1 !important;
}

.form-inner form input:-moz-placeholder {
  color: #A4AAB8 !important;
  opacity: 1 !important;
}

.form-inner form input::-webkit-input-placeholder {
  color: #A4AAB8 !important;
  opacity: 1 !important;
}
.custom-control-label {
    cursor: pointer;
}
.custom-control-label::before {
    width: 18px;
    height: 18px;
    border-radius: 3px !important;
    border: 1px solid #959595;
    background: none;
}
.custom-control .custom-control-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #001E61;
    padding-left: 5px;
}
.custom-control-label::after {
  top: 5px;
  left: 1px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #B78B1E;
}
.custom-control-input:active ~ .custom-control-label::before {
  background-color: rgba(183,139,30,.5);
}
.password-text {
    color: #4E5D81;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}
.password-text:hover {
    color: #B78B1E;
}
.form-inner .btn-civic {
    width:100%;
    padding:15px;
}
.btn-civic {
    cursor: pointer;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    background: #B78B1E;
    transition: all 0.5s ease;
    color:#fff;
    border:none;
    border-radius: 10px;
    display: inline-block;
}
.btn-civic:hover {
    background: #001E61;
    color:#fff;
}
.right-contain {
    background-image: url('../images/form-bg.png');
    background-repeat:  no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
    background-position: top center;
}
.right-contain:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 30, 97, 0.8);
    left: 0;
    top: 0;
}
.form-contain .row .col-xl-6:first-child {
    padding-right:0;
}
.form-contain .row .col-xl-6:last-child {
    padding-left: 0;
}
.right-contain h1 {
    padding-top: 225px;
    font-weight: 300;
    font-size: 40px;
    line-height: 47px;
    color:#fff;
    position: relative;
    z-index: 99;
    text-transform: uppercase;
}
.right-contain h1 span {
    font-weight: 500;
    clear: both;
    float: left;
    width: 100%;
    font-size: 45px;
    line-height: 53px;
}
.password-icon {
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}
.back-text {
    margin-top: 40px;
}
.back-text a {
    color:#4E5D81;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    transition: all 0.5s ease;
}
.back-text a img {
    margin-right: 2px;
}
.back-text a:hover {
    color:#B78B1E;
}
.error-msg {
    color:#FC1E00;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
button[type="submit"]:disabled {
    background: #A0A0A0;
    opacity: 1 !important;
}
.error-input input {
    border-color:#FC1E00 !important;
}
#eyeIcon svg {
    color: #313E5B !important;
}
#eyeIcon:hover svg,
.back-text a:hover svg {
    color: #B78B1E !important;
}
.fa-sign-out::before {
  content: "\f2f5";
}
.error-input svg {
    color:#FC1E00 !important;
}
.back-text svg {
    width: 14px;
    height: 12px;
    position: relative;
    top: -1px;
    color: #4E5D81 !important;
}
.show-password,
.hide-password,
.left-arrow,
.refresh-icon,
.logout-icon span,
.error-icon,
.left-angle,
.phone-icon,
.mail-icon,
.search-icon,
.prev-arrow,
.next-arrow,
.close span,
.filter-top,
.filter-bottom {
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    transition: all 0.5s ease;
}
.show-password:before {
    content: "\e905";
    color: #313e5b;
}
.hide-password:before {
    content: "\e904";
    font-size: 12px;
    color: #313e5b;
}
.left-arrow:before {
    content: "\e907";
    font-size: 8px;
    position: relative;
    top: -1px;
}
.hide-password,
.show-icon .show-password {
    display: none;
}
.left-angle:before {
    content: "\e906";
    font-size: 16px;
    position: relative;
    top: 1px;
}
.show-icon .hide-password {
    display: inline-block;
}
.back-text .left-arrow {
    margin-right: 2px;
}
.refresh-icon:before {
    content: "\e90e";
    font-size: 10px;
    position: relative;
    top: -1px;
}
.phone-icon:before {
    content: "\e90c";
    position: relative;
    top: 1px;
}
.mail-icon:before {
    content: "\e909";
    font-size: 9px;
}
.logout-icon span {
    display: block;
}
.logout-icon span:before {
    content: "\e908";
    font-size: 20px;
    color: #B78B1E;
    transition: all 0.5s ease;
}
.logout-icon:hover span:before {
    color: #f5f5f5 !important;
}
.error-icon {
    cursor: default;
}
.error-icon:before {
    content: "\e903";
    color: #FC1E00;
    font-size: 18px;
}
.show-password:hover:before,
.hide-password:hover:before {
    color: #B78B1E;
}
.search-icon:before {
    content: "\e900";
    color: #aaa;
    font-size: 14px;
    position: relative;
    top: -2px;
}
.prev-arrow:before {
    content: "\e90d";
    position: relative;
    top: 1px;
    color:#001E61;
}
.next-arrow:before {
    content: "\e90a";
    position: relative;
    top: 1px;
    color:#001E61;
}
.close span:before {
    content: "\e901";
    font-size: 12px;
    position: relative;
    top: -3px;
    color: #569cc1;
}
.close span:hover:before {
    color:#001E61;
}

header {
    background: #001E61;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
header .header-right {
    display: flex;
    align-items: center;
}
header .header-right h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color:#fff;
    margin-right: 20px;
}
.logo-home {
    width: 400px;
}
header.sticky-header {
  position: fixed;
  width: 100%;
  z-index: 999999;
  transition: 0.3s ease all;
  /*transition: all 0.5s ease;*/
  /*-webkit-animation: sticky-menu 0.8s forwards;
  -ms-animation: sticky-menu 0.8s forwards;
  -o-animation: sticky-menu 0.8s forwards;
  animation: sticky-menu 0.8s forwards;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);*/
  top: 0;
  /*padding-top: 20px;
  padding-bottom: 20px;*/
}
/*@-webkit-keyframes sticky-menu {
  from {
    top: -50%;
  }
  to {
    top: 0;
  }
}
@keyframes sticky-menu {
  from {
    top: -50%;
  }
  to {
    top: 0;
  }
}*/
.btn-civic {
    padding: 10px 25px;
}
.btn-civic .refresh-icon {
    margin-right: 5px;
}
.logout-icon {
    margin-left: 18px;
    margin-left: 18px;
}
.logout-icon svg {
    position: relative;
    top: 4px;
}
.header-right .dropdown {
    margin-left: 30px;
}
.header-right .dropdown button,
.header-right .dropdown button:hover,
.header-right .dropdown button:active,
.header-right .dropdown button:focus,
.header-right .dropdown button:focus-visible {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
}
.header-right .dropdown button {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    padding: 0;
    text-align: left;
    color:#fff;
    cursor: pointer;
}
.header-right .dropdown button:hover,
.header-right .dropdown button span:hover {
    color:#f5f5f5;
}
.header-right .dropdown button span {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    float: left;
    clear: both;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.header-right .dropdown-toggle::after {
  border-top: 6px solid;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  margin-left: 10px;
  position: relative;
  top: 0;
}
.header-right .dropdown-menu {
    top: 33px !important;
    padding: 0;
    margin: 0;
    border: 1px solid #ABABAB;
    border-radius: 14px;
}
.header-right .dropdown-menu:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: -10px;
    right: 20px;
    z-index: 9999;
}
.header-right .dropdown-menu:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #ABABAB;
    top: -12px;
    z-index: -1;
    right: 18px;
}
header .btn-civic:hover {
    background: #a07408;
    color:#fff;
}
.dropdown-menu .dropdown-item:hover {
    background: none;
    color: #B78B1E;
}
.dropdown-menu .dropdown-item {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #3B3B3B;
    padding:8px 20px;
}
.wrapper {
  position: relative;
  min-height: 100vh;
}
footer {
    color: #797979;
    /*font-weight: 300;*/
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
footer .pull-right{
    color: #383838;
}
.password-error img {
    margin-right: 5px;
}
.password-error img:last-child {
    margin-right: 0;
}
.password-error input {
    padding: 0 50px 15px 0;
}

.change-form .left-form {
  /*height: calc(100vh - 147px);*/
  height: calc(100vh - 47px);
  min-height: 760px;
}
.change-form .form-inner {
    margin-top: 0;
}
.sync-section {
    position: relative;
}
.success-sync {
    position: absolute;
    min-width: 450px;
    background: #D1EFFF;
    border-radius: 10px;
    border: 1px solid #91d2f4;
    color: #001E61;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    padding: 13px 60px 13px 20px;
    right: 25px;
    top: 71px;
    opacity: 0;
    transition: all 0.5s ease;
    visibility: hidden;
}
.fa-times {
  width: 18px;
  height: 18px;
}
.success-sync .fa-times {
  color: #569CC1;
}
.success-sync:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #D1EFFF;
    position: absolute;
    top: -10px;
    right: 20px;
    z-index: 9999;
}
.success-sync:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid #91d2f4;
    top: -12px;
    z-index: -1;
    right: 18px;
}
.success-sync button {
    position: absolute;
    right: 20px;
    top: calc(50% - 14px);
    opacity: 1;
    line-height: inherit;
}
.add-sync .sync-icon {
    animation: animName 2s linear infinite;
}
.btn-civic img {
  margin-right: 5px;
  position: relative;
  top: -2px;
}
@keyframes animName {
 0%{
    transform: rotate(0deg);
   }
100%{
    transform: rotate(360deg);
   }
}
.add-sync .success-sync {
    opacity: 1;
    visibility: visible;
}
:where(.css-dev-only-do-not-override-1km3mtt).ant-empty .ant-empty-image svg {
    width: 100%;
}
/*---- Member Directory ----*/
.btn-civic.gray-bg {
    background: #D9D9D9;
    color:#001E61;
}
.btn-civic.gray-bg:hover {
    background: #d1caca;
}
.bg-shadow {
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 15px;
}
.filter-section {
    padding: 15px 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
.label-filter {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #3B3B3B;
    margin-right: 25px;
}
.single-filter {
    float: left;
    margin-right: 28px;
}
.single-filter:last-child {
    margin-right: 0;
}
.single-filter label {
    margin-bottom: 0;
    color:#001E61;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    position: relative;
    top: 7px;
}
.single-filter select {
    margin-left: 20px;
    min-width: 120px;
    border: none;
    border-bottom:1px solid #D9D9D9;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding:0 0 5px 0;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    background: url('../images/down-arrow.svg') no-repeat 100% 38%;
}
.all-btn {
    margin-left:27px;
}
.all-btn .btn-civic {
    margin-right: 20px;
}
.all-btn .btn-civic:last-child {
    margin-right: 0px;
}
.member-contain {
    margin-top: 20px;
    overflow: hidden;
}
.top-section {
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
}
.main-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
}
.main-title .badge {
    background: #D3E1FF;
    border-radius: 16px;
    color: #001E61;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding: 2px 8px;
    margin-left: 5px;
}
.search-right {
    position: relative;
}
.search-right .form-control {
    width: 404px;
    padding: 0 0 9px 23px;
    border-color: #D9D9D9;
}
.search-right input:-ms-input-placeholder {
  color: #3B3B3B !important;
  opacity: 1 !important;
}

.search-right input::-moz-placeholder {
  color: #3B3B3B !important;
  opacity: 1 !important;
}

.search-right input:-moz-placeholder {
  color: #3B3B3B !important;
  opacity: 1 !important;
}

.search-right input::-webkit-input-placeholder {
  color: #3B3B3B !important;
  opacity: 1 !important;
}
.search-icon {
    position: absolute;
    left: 0;
    top: 2px;
    cursor: pointer;
}
.search-icon:hover:before {
    color:#B78B1E;
}
input:focus {
    border-color: #B78B1E !important;
}
.search-right input:focus-visible,
select:focus-visible {
    outline: none;
}
.search-right .form-group {
    margin-bottom: 0;
}

table.table thead tr th {
    background: #F9FAFB;
    padding: 13px 15px;
}
table.table thead tr th,
table.table tbody tr th {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #667085;
}
table.table {
    margin-bottom: 0;
    border-bottom: 1px solid #EAECF0;
}
table.table thead tr th:first-child,
table.table tbody tr th {
    text-align: center;
}
table.table thead th {
    border-top: 0;
    border-bottom: 1px solid #EAECF0;
}
table.table tbody tr {
    transition: all 0.5s ease;
}
table.table tbody tr th {
    font-weight: 400;
    vertical-align: middle;
}
table.table tbody tr td {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3B3B3B;
    border-color: #EAECF0;
    padding: 20px 15px;
    vertical-align: middle;
}
table.table tbody tr:hover {
    background: #EEF3FB;
}
.pagination-wrapper {
    padding: 20px 25px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pagination-wrapper .left-page-title label {
    color:#5B5B5B;
}
.pagination-wrapper .left-page-title select {
    margin-left: 12px;
    margin-right: 12px;
    min-width: 45px;
    color:#5B5B5B;
}
.pagination button {
    text-align: center;
    color: #667085;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    transition: all 0.5s ease;
    line-height: 36px;
    min-width: 38px;
    border: 1px solid transparent;
    min-height: 38px;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.pagination button:hover,
.pagination button.active-page {
    background: #D3E1FF;
    border: 1px solid #D3E1FF;
    border-radius: 8px;
    color:#001E61;
}
.pagination button.dot-page {
    min-width: 40px;
    min-height: 40px;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination button.prev-page,
.pagination button.next-page {
    margin-right: 10px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    min-width: 36px;
    min-height: 36px;
    margin-top: 1px;
    line-height: 34px;
}
.pagination button {
    background: none;
}
.pagination button.next-page {
    margin-right: 0;
    margin-left: 10px;
}
.pagination button.prev-page:hover,
.pagination button.next-page:hover,
.pagination button.prev-page:hover span:before,
.pagination button.next-page:hover span:before {
    border-color: #a07408;
    color: #a07408 !important;
}
.pagination button.prev-page .fa-arrow-left,
.pagination button.next-page .fa-arrow-right {
    position: relative;
    top:1px;
}
.pagination button.prev-page:hover,
.pagination button.next-page:hover {
    background: none;
}

/*---- Member Detail ----*/
.member-top-detail {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    margin-top: 20px;
}
.main-title .left-angle {
    margin-right: 20px;
    /*position: relative;
    top: 2px;*/
}
.main-title a {
    color: #101828;
    transition: all 0.5s ease;
}
.main-title a:hover {
    color:#a07408;
}
.breadcrumb {
    padding: 0;
    background: none;
    margin: 0;
}
.breadcrumb li,
.breadcrumb li a {
    color: #5B5B5B;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
}
.breadcrumb li a:hover {
    color:#a07408;
}
.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 5px;
  padding-left: 5px;
}
.member-detail {
    padding: 60px;
    margin-top: 20px;
}
.left-member-detail h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #101828;
    margin-bottom: 5px;
}
.left-member-detail p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #3B3B3B;
}
.left-member-detail ul {
    margin-top: 15px;
}
.left-member-detail ul li {
    margin-bottom: 2px;
}
.left-member-detail ul li a {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #3B3B3B;
    transition: all 0.5s ease;
}
.left-member-detail ul li a:hover {
    color:#a07408;
}
.left-member-detail ul li a span {
    display: inline-block;
    width: 15px;
}
.right-member-detail {
    border-left: 1px solid #D9D9D9;
    padding-left: 45px;
}
.single-detail p.gray-color {
    color: #6A6A6A;
}
.single-detail {
    margin-bottom: 50px;
}
.single-detail p {
    margin-bottom: 5px;
}
.single-detail p:last-child {
    margin-bottom: 0;
}


.filter-icon {
	/*float: right;
	position: relative;
	top: 8px;*/
    float: left;
    width: 10px;
    display: inline-block;
    position: relative;
    margin-left: 10px;
}
.filter-top {
    position: absolute;
    top: 2px;
}
.filter-bottom {
    position: absolute;
    bottom: 10px;
}
.filter-top:before {
	content: "\e90f";
	font-size: 6px;
	color: #667085;
}
.filter-bottom:before {
	content: "\e910";
	font-size: 5px;
	color: #667085;
}
table.table thead tr th a {
	color: #667085;
    display: flex;
}
table.table thead tr th a:hover,
.filter-top:hover:before,
.filter-bottom:hover:before {
	color: #001E61;
}
.filter-icon.filter-top-icon {
	top: -3px;
}
.filter-icon.filter-bottom-icon {
    top: 3px;
}

/*---- Process ----*/
.progress-loader {
    position: relative;
    display: flex;
    justify-content: center;
}
.dot-flashing {
  display: none;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #313e5b;
  color: #313e5b;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  bottom: -20px;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #313e5b;
  color: #313e5b;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #313e5b;
  color: #313e5b;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #313e5b;
  }
  50%, 100% {
    background-color: rgba(49, 62, 91, 0.2);
  }
}

.progress-loader.add-progress .dot-flashing {
    display: inline-block;
}
:where(.css-dev-only-do-not-override-diro6f).ant-empty .ant-empty-image svg{
    width: 100% !important;
}
.change-form .error-input .error-msg,
.reset-pass-form .error-input .error-msg {
    position: relative;
    bottom: 0;
}
.logo {
    width: 350px;
}

footer .footer-logo {
    max-width: 90px;
    margin-left: 5px;
    position: relative;
    top:-2px;
}
.login-footer {
    margin-top:15px;
    font-size: 14px;
}
.login-logo img {
    max-width: 90px;
    position: relative;
    top:-2px;
}
.login-logo {
    color: #383838;
}
footer .container,
.login-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #797979;
}

@media (min-width: 1200px){
    .container {
      max-width: 1614px;
    }
    .wrapper {
        /*min-height: 1020px;*/
    }
}
@media (min-width: 1366px){
    .member-detail .row .col-md-4 {
        -ms-flex: 0 0 240px;
        flex: 0 0 240px;
        max-width: 240px;
    }
    .member-detail .row .col-md-8 {
        -ms-flex: 0 0 calc(100% - 240px);
        flex: 0 0 calc(100% - 240px);
        max-width: 0 0 calc(100% - 240px);
    }
    .label-filter {
        position: relative;
        top: -1px;
    }
}
@media (min-width: 1800px){
    .change-form .left-form {
        min-height: 890px;
    }
    .change-form .left-form {
        height: calc(100vh - 47px);
    }
}
@media(max-width:1650px){
    .loader.loader1 {
      top: 8px;
    }
   .form-inner {
        margin-top: 20px;
        padding: 30px 40px;
    }
    .form-inner form {
        margin-top: 10px;
    }
    .right-contain h1 {
        padding-top: 160px;
        font-size: 35px;
        line-height: 40px;
    }
    .right-contain h1 span {
        font-size: 40px;
        line-height: 48px;
    }
    .form-bottom {
      margin-bottom: 25px;
    }
    .single-filter select {
      margin-left: 15px;
      min-width: 87px;
    }
    .all-btn .btn-civic {
      margin-right: 10px;
    }
    .all-btn {
      margin-left: 15px;
    }
    .single-filter {
      float: left;
      margin-right: 16px;
    }
    table.table tbody tr td {
      padding: 15px;
    }
    .label-filter {
      margin-right: 15px;
    }
    .logo-home {
        width: 300px;
    }
}
@media(max-width:1365px){
    .filter-option {
        width:calc(100% - 290px);
    }
    .change-form .left-form {
        height: auto;
        padding-top: 100px;
        padding-bottom: 80px;
    }
    table.table thead tr th.compnay-text {
        width:300px;
        min-width:300px;
    }
    .single-filter {
      margin-top: 3px;
      margin-bottom: 3px;
    }
    .single-filter label {
      min-width: 80px;
      /*position: relative;
      top: -4px;*/
    }
    .single-filter select {
      min-width: 155px;
    }
    .single-detail {
      margin-bottom: 30px;
    }
    .filter-option .single-filter select {
        margin-left: 10px;
        min-width: calc(100% - 121px);
        float: right;
    }
    .filter-option .single-filter {
      width: calc(33.3% - 16px);
    }
    .form-inner {
        margin-top: 20px;
        padding: 25px 40px;
    }
    .form-inner .btn-civic {
        padding: 12px 15px;
    }
    .left-form {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .form-bottom {
        margin-bottom: 15px;
    }
}
@media(max-width:1199px){
    .container {
      max-width: 100%;
    }
    .back-text {
      margin-top: 25px;
    }
    .form-inner form .form-group {
      margin-top: 10px;
    }

    .all-btn {
      margin-left: 4px;
    }
    table.table tbody tr td,
    table.table thead tr th,
    table.table tbody tr th {
      padding: 10px;
    }

    .success-sync {
      min-width: 390px;
      font-size: 14px;
      line-height: 26px;
      padding: 8px 45px 8px 15px;
    }
}

@media(max-width:991px) {
    .login-footer {
        padding-bottom: 15px;
    }
    .form-contain .row .col-xl-6:first-child {
        padding-right:15px;
    }
    .form-contain .row .col-xl-6:last-child {
        padding-left: 15px;
    }
    .main-title .left-angle {
      margin-right: 10px;
    }
    .loader.loader1 {
      width: 100%;
    }
    .loader.loader1 {
      position: absolute;
      top: 7px;
    }
    .loader {
      top: 120px;
    }
    .loader.loader1 span {
        margin-right: 0;
        font-size: 12px;
        padding: 2px 10px;
    }
    .right-member-detail {
      padding-left: 30px;
    }
    .right-contain h1 {
      padding-top: 50px;
    }
    .right-contain {
        background-image: url('../images/form-bg-t.jpg');
        min-height: 400px;
        height: auto;
    }
    .form-contain {
        height: auto;
        overflow: hidden;
    }
   .left-form {
      height: calc(100vh - 400px);
      padding: 30px 0;
      min-height: 550px;
    }
    .form-inner form {
      margin-top: 25px;
    }
    .form-inner {
        margin-top: 15px;
        padding: 30px;
    }
    .change-form .left-form {
        padding-top: 60px;
        padding-bottom: 45px;
        min-height: 600px;
    }
    .btn-civic {
      font-size: 14px;
      line-height: 16px;
    }
    .header-right .dropdown {
      margin-left: 20px;
    }
    header .header-right h3 {
      margin-right: 15px;
    }
    .logo-home {
        width: 230px;
    }
    .header-right .dropdown button span {
        font-size: 16px;
    }
    .all-btn .btn-civic {
      margin-right: 5px;
    }
    .single-filter label {
        font-size: 13px;
    }
    .all-btn {
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
    .filter-option {
      width: calc(100% - 80px);
    }
    .pagination-wrapper .left-page-title select {
      margin-left: 8px;
      margin-right: 8px;
    }
    .pagination-wrapper {
      padding: 20px 20px 15px 20px;
    }
    .pagination button.next-page {
      margin-right: 0;
      margin-left: 5px;
    }
    .pagination button.prev-page {
      margin-right: 5px;
    }
    .search-right .form-control {
      width: 275px;
    }

    .member-top-detail {
      padding: 15px 20px;
    }
    .member-detail {
      padding: 40px;
    }
    .success-sync {
        top: 59px;
    }
    header {
      padding: 15px 20px;
    }

    .single-filter label {
      top: 0px;
    }
}
@media(max-width:767px) {
    .change-form .left-form {
        height: calc(100vh - 47px);
    }
    .logo {
        width: 270px;
    }
    .main-title .left-angle {
        margin-right: 10px;
    }
    .left-form {
      height: calc(100vh - 300px);
    }
    .right-contain {
        background-image: url('../images/form-bg-m.jpg');
        min-height: 300px;
    }
    .back-text {
      margin-top: 15px;
    }
    .right-contain h1 {
      padding-top: 40px;
      font-size: 30px;
      line-height: 35px;
    }
    .right-contain h1 span {
      font-size: 35px;
      line-height: 43px;
    }
    .form-inner h2 {
      font-size: 24px;
    }

    header {
      padding: 15px;
    }
    header .header-right {
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    }
    header .header-right h3 {
      margin-right: 15px;
      font-size: 13px;
    }
    .btn-civic {
      padding: 8px 20px;
      font-size: 14px;
    }
    .header-right .dropdown {
      margin-left: 15px;
    }
    .header-right .dropdown button {
      font-size: 12px;
    }
    .header-right .dropdown button span {
      font-size: 14px;
        max-width: 110px;
    }
    .logout-icon {
      margin-left: 10px;
    }
    .change-form .left-form {
        padding-top: 10px;
        padding-bottom: 15px;
        min-height: 610px;
    }
    .pagination-wrapper {
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
    }
    .left-page-title {
        margin-bottom: 10px;
        width: 100%;
        text-align: center;
    }
    .left-page-title .single-filter {
        width: 100%;
    }
    .top-section {
      justify-content: center;
      flex-wrap: wrap;
    }
    .top-section .main-title {
        margin-bottom: 15px;
        width: 100%;
        text-align: center;
    }
    .single-filter select {
      min-width: 60px;
    }
    .label-filter {
      margin-right: 0;
      width: 100%;
      text-align: center;
    }
    .filter-option {
      width: 100%;
    }
    .filter-section .single-filter {
      margin-right: 10px;
      width: calc(50% - 5px);
    }
    .filter-option .single-filter:nth-child(even) {
        margin-right: 0;
    }

    .right-member-detail {
      border-left: none;
      padding-left: 0;
      margin-top: 40px;
    }
    .member-detail {
      padding: 15px 20px 0 20px;
    }
    .right-member-detail {
        margin-top: 20px;
    }
    .single-detail {
      margin-bottom: 20px;
    }
    .success-sync {
      top: 57px;
    }

    .member-top-detail {
      padding: 10px 20px;
      flex-wrap: wrap;
      justify-content: center;
    }
    .member-top-detail .main-title {
        width: 100%;
        justify-content: flex-start;
        padding-left: 25px;
        line-height: 20px;
    }
    .member-top-detail {
        justify-content: flex-start;
        position: relative;
    }
    .member-top-detail nav {
        margin-left: 25px;
    }
    .main-title .left-angle {
        position: absolute;
        top: calc(50% - 11px);
        left: 20px;
    }
    .left-angle::before {
        font-size: 20px;
    }

    .dot-flashing {
        bottom: -18px;
        width: 8px;
        height: 8px;
    }
    .dot-flashing::before,
    .dot-flashing::after {
      width: 8px;
      height: 8px;
    }

    header {
        flex-wrap: wrap;
        justify-content: center;
    }
    .logo-home {
        width: 270px;
        margin-bottom: 10px;
    }
    .form-inner {
        padding: 50px;
    }
    .form-inner.welcome-form h2 {
        margin-bottom: 15px;
    }
}
@media(max-width:575px) {
    .login-footer {
        padding-top: 10px;
    }
    .form-inner {
        margin-top: 20px;
    }
    .form-inner {
        padding: 35px;
    }
    .left-form {
      padding: 40px 0;
    }
    header .header-right {
      justify-content: flex-end;
    }
    .header-right .dropdown,
    .logout-icon {
        margin-top: 5px;
    }
    .filter-section,
    .pagination-wrapper {
      padding: 15px;
    }
    .left-page-title {
      margin-bottom: 5px;
    }
    .filter-section .single-filter select {
      min-width: calc(100% - 113px);
      float: right;
    }

    .success-sync {
      top: 50px;
      z-index: 9;
    }
}
@media(max-width:479px) {
    .form-inner {
        padding: 30px 20px;
    }
    .form-inner.welcome-form h2 {
        margin-bottom: 10px;
    }
    footer .container,
    .login-footer{
        display: inline-block;
        width: 100% !important;
        text-align:center;
    }
    footer .container .pull-right,
    .login-footer .pull-right {
        margin-top:10px;
    }
    .loader {
      top: 170px;
    }
    .left-form {
      min-height: 526px;
    }

    .change-form .left-form {
      /*min-height: 580px;*/
    }
    .left-form {
      width: 90%;
    }
    .form-inner form {
      margin-top: 15px;
    }
    .form-inner p {
      font-size: 13px;
    }
    .form-inner .btn-civic {
      padding: 12px;
    }
    .password-text {
      font-size: 13px;
    }
    .form-bottom {
        margin-bottom: 20px;
    }
    .right-contain h1 {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 25px;
        line-height: 30px;
    }
    .right-contain h1 span {
      font-size: 30px;
      line-height: 38px;
    }
    .logo-home {
      width: 250px;
      margin-bottom: 10px;
    }
    header .header-right h3 {
      margin-right: 0;
      width: 100%;
      text-align: center;
      padding-bottom: 5px;
    }
    header .header-right {
      justify-content: center;
    }
    .header-right .dropdown {
      margin-left: 15px;
    }
    .logout-icon {
      margin-left: 15px;
    }
    .filter-section .single-filter {
      margin-right: 0;
      width: 100%;
    }
    .top-section {
      padding: 15px;
    }
    .search-right,
    .search-right .form-control {
      width: 100%;
    }
    .pagination .prev-page img, .pagination .next-page img {
        width: 10px;
    }
    .pagination button.prev-page, .pagination button.next-page {
        width: 10px;
        min-width: 28px;
        min-height: 26px;
        margin-top: 0;
        line-height: 26px;
    }
    .pagination button {
      line-height: 28px;
      min-width: 28px;
      min-height: 28px;
      font-size: 13px;
    }
    .pagination button.dot-page {
      min-width: 30px;
      min-height: 30px;
    }
    .member-detail .right-member-detail .row .col-6 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .success-sync {
        left: 0;
        right: auto;
        min-width: 260px;
        line-height: 20px;
    }
    .success-sync::after {
      left: 18px;
      right: auto;
    }
    .success-sync::before {
        left: 20px;
        right: auto;
    }
    logo {
        width: 240px;
    }
}
